import ReactPlayer from "react-player";
import styled from "styled-components";

export const StyledReactPlayer = styled(ReactPlayer)`
  width: 100% !important;
  height: 100vh !important;
  max-height: 650px !important;

  border-radius: 25px !important;

  @media (max-width: 1850px) {
    max-height: 550px !important;
  }

  @media (max-width: 1550px) {
    max-height: 450px !important;
  }

  @media (max-width: 1200px) {
    max-height: 350px !important;
  }

  @media (max-width: 960px) {
    max-height: 285px !important;
  }

  @media (max-width: 768px) {
    max-height: 685px !important;
  }

  @media (max-width: 600px) {
    max-height: 450px !important;
  }

  @media (max-width: 480px) {
    max-height: 450px !important;
  }
`;

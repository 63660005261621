import "react-toastify/dist/ReactToastify.css";
import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  Title,
  StyledButton,
  StyledTextField,
  PageChip,
  Description,
  UploadBox,
  UploadText,
  TypeUploadText,
  ColoredTitle,
  BottomText,
} from "./styles";
import { mail } from "services";
import SmallStyled from "components/SmallStyled";
import { ToastContainer, toast } from "react-toastify";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import Config from "data/config";
interface iFormContact {
  name: string;
  email: string;
  phone: string;
  file: any;
}

export default function WorkWhitUsForm() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<any>(null);
  const [errorFile, setErrorFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<iFormContact>();

  const handleBoxClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setErrorFile(false);
      setValue("file", event.target.files[0]);
      setFile(event.target.files[0]);
    } else {
      setErrorFile(true);
      setValue("file", null);
      setFile(null);
    }
  };

  const onSubmit: SubmitHandler<iFormContact> = async (data) => {
    if (!file) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
      try {
        setLoading(true);
        const resonse = await mail.sendMail({
          type: "work_whit_us",
          data,
        });

        if (resonse.status) {
          setValue("name", "");
          setValue("email", "");
          setValue("phone", "");
          setValue("file", "");
          toast("Currículo enviado com sucesso!", {
            type: "success",
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error: any) {
        toast("Erro ao solicitar contato. Redirecionando para o Whatsapp!", {
          type: "error",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          const message = `Olá acessei o website e gostaria de fazer parte do time, esses são meus dados:
          \n
          \nNome *${data.name}*
          \nEmail: *${data.email || ""}*
          \nTelefone: *${data.phone || ""}*`;

          const link = createLinkWhatsapp(
            Config.contact.whatsapp.number,
            encodeURIComponent(message)
          );

          openLink(link, "_blank");
        }, 3000);
      }
    }
    setLoading(false);
  };

  const applyPhoneMask = (value: string) => {
    return value
      .replace(/\D/g, "") // Remove tudo que não é dígito
      .replace(/(\d{2})(\d)/, "($1) $2") // Adiciona parênteses ao DDD
      .replace(/(\d{1})(\d{4})(\d{5})/, "$1 $2-$3") // Adiciona espaço e hífen no formato correto
      .slice(0, 16); // Limita o tamanho a 16 caracteres (formato (67) 9 8113-31545)
  };

  return (
    <Box py={7} bgcolor="#282828">
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          px={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          pb={4}
        >
          <PageChip>#TIMEITOP</PageChip>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pb={4}>
          <Title>Trabalhe conosco</Title>
        </Grid>
        <Grid
          item
          xs={11}
          sm={9}
          md={6}
          lg={4.5}
          xl={3.5}
          textAlign="center"
          pb={3}
        >
          <Description>
            Se você está interessado em se juntar à nossa equipe, envie seu
            currículo. Aceitamos candidaturas espontâneas.
          </Description>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid pt={3} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="Nome"
              {...register("name", { required: true, minLength: 5 })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === "required" && (
              <SmallStyled>Nome é obrigatório</SmallStyled>
            )}
            {errors.name?.type === "minLength" && (
              <SmallStyled>
                Nome deve possuir no mínimo 5 caracteres
              </SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="E-mail"
              {...register("email", {
                required: true,
                minLength: 5,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address",
                },
              })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email?.type === "required" && (
              <SmallStyled>Email é obrigatório</SmallStyled>
            )}
            {errors.email?.type === "pattern" && (
              <SmallStyled>Email deve ser válido</SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              rules={{
                required: "Telefone é obrigatório",
                minLength: {
                  value: 14,
                  message: "Telefone deve ser válido",
                },
              }}
              render={({ field }) => (
                <>
                  <StyledTextField
                    {...field}
                    placeholder="Telefone com DDD"
                    value={applyPhoneMask(field.value)}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={!!errors.phone}
                  />
                  {errors.phone?.type === "required" && (
                    <SmallStyled>Telefone é obrigatório</SmallStyled>
                  )}
                  {errors.phone?.type === "minLength" && (
                    <SmallStyled>Telefone deve ser válido</SmallStyled>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <UploadBox onClick={handleBoxClick}>
              {file === null ? (
                <Box>
                  <img src="images/workWhitUs/upload-icon.png" alt="icon" />
                  <UploadText>Upload de cúrriculo</UploadText>
                  <TypeUploadText>
                    Formatos aceitos: PDF, DOC. DOCX
                  </TypeUploadText>
                </Box>
              ) : (
                <UploadText>{file.name}</UploadText>
              )}
            </UploadBox>
            <input
              accept=".pdf,.doc,.docx"
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {errorFile && <SmallStyled>Currículo é obrigatório</SmallStyled>}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledButton type="submit">
              <>
                {loading || "Enviar currículo"}
                {loading && <CircularProgress />}
              </>
            </StyledButton>
          </Grid>
        </Grid>
        <Grid pt={6} container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={11}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            textAlign="center"
            pb={3}
          >
            <BottomText>
              Ou envie seu currículo e carta de apresentação para o e-mail
              <ColoredTitle> rh@itop.net.br</ColoredTitle>
            </BottomText>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </Box>
  );
}


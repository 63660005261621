import request from "helpers/Request";

async function postLogin({
  user,
  password,
}: {
  user: string;
  password: string;
}): Promise<any> {
  const params = new URLSearchParams();
  params.append("user", user);
  params.append("password", password);
  params.append("sys", "SAC");
  params.append("cpf", "");
  params.append("email", "");

  try {
    console.log("------", params.toString());
    const loginUrl = "https://mkbot.itop.net.br/sac/logon.do";
    const response = await request.post(loginUrl, params.toString(), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    // Expressão regular para capturar o valor de window.location ou top.location.href
    const match = response.data.match(
      /(?:window\.location\s*=|top\.location\.href\s*=)\s*['"]([^'"]+)['"]/
    );

    if (match && match[1] && match[1].includes("open.do?sys=SAC")) {
      console.log("Valor de redirecionamento:", match[1]);
      console.log(`${loginUrl}?${params.toString()}`);

      return {
        status: true,
        redirect: `${loginUrl}?${params.toString()}`,
      };
    } else {
      return {
        status: false,
        data: response.data,
      };
    }
  } catch (error: any) {
    return {
      status: false,
      data: error.response ? error.response.data : "Erro desconhecido",
    };
  }
}

const actions = {
  postLogin
};

export default actions;


import { useEffect, useRef, useState } from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledList, StyledListBox, StyledListItem } from "./styles";
import LocalStorage from "helpers/LocalStorage";
import { iCity } from "data/interfaces/cities";

type PropsInput = {
  cities: iCity[];
};

export default function ListCities({ cities }: PropsInput) {
  const [visibleCities, setVisibleCities] = useState(10); // Quantidade inicial de cidades visíveis
  const navigate = useNavigate();
  const listRef = useRef<HTMLUListElement>(null);

  const loadMoreCities = () => {
    setVisibleCities((prevVisibleCities) => prevVisibleCities + 10);
  };

  const handleScroll = () => {
    if (
      listRef.current &&
      listRef.current.scrollTop + listRef.current.clientHeight ===
        listRef.current.scrollHeight
    ) {
      loadMoreCities();
    }
  };

  const hangleChangeCity = (city: iCity) => {
    LocalStorage.set("city", city);
    navigate("/");
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledListBox>
      <StyledList ref={listRef}>
        {cities.slice(0, visibleCities).map((city: any, index: number) => (
          <StyledListItem key={city.id} disablePadding>
            <ListItemButton
              onClick={() => hangleChangeCity(city)}
              disableGutters
            >
              <ListItemText primary={`${city.city} - ${city.uf}`} />
            </ListItemButton>
          </StyledListItem>
        ))}
        {visibleCities < cities.length && (
          <StyledListItem key={"load-more-city"} disablePadding>
            <ListItemButton onClick={loadMoreCities}>
              <ListItemText primary={`Carregar mais cidades`} />
            </ListItemButton>
          </StyledListItem>
        )}
      </StyledList>
    </StyledListBox>
  );
}


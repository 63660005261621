import styled from "styled-components";
import { TextField, Box } from "@mui/material";
import { LoadingButton } from '@mui/lab'

export const FullHeightBox = styled(Box)`
  width: 100%;
  height: 100%;
    background-color: #2e2e2e;
  @media (max-width: 1000px) {
    width: 100%;
    height: 100%;
  }
`;

export const FullHeightImage = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;

  @media (max-width: 900px) {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.div`
  text-align: center;
  color: #ff7c06;
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const StyledButton = styled(LoadingButton)`
  && {
    background-color: #ff7c06;
    text-transform: none;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 800;
    border-radius: 30px;
    width: 100%;
    color: black;
    height: 55px;

    &:hover {
      background-color: #f9963d;
      color: black;
    }

    // Style for loading state
    &.MuiLoadingButton-loading {
      background-color: #d46f04;
      color: black;

      .MuiLoadingButton-loadingIndicator {
        color: white; // Customize the loading spinner color here
      }
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    background-color: #2E2E2E;
    border-radius: 8px;
    &.Mui-focused fieldset {
      border-color: #ff7c06;
    }
       & fieldset {
      border-color: #C0C0C0; /* Cor padrão da borda */
    }  

  & .MuiInputBase-input {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff; /* Cor do texto digitado */
  }
  & .MuiInputBase-input::placeholder {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #C0C0C0;
    opacity: 60%;
  }
`;

export const ForgetPassword = styled.div`
  text-align: center;
  cursor: pointer;
  color: #C0C0C0;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
`;
import { Box } from "@mui/material";
import Initial from "../../components/ReturnEquipment/Initial/index";
import ReturnForm from "../../components/ReturnEquipment/Form/index";
export default function ReturnEquipment() {
  return (
    <Box width="100%" height="100%">
      <Initial />
      <ReturnForm />
    </Box>
  );
}

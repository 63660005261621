import Config from "data/config";
import request from "helpers/Request";
import { ApiResponse, ResponseError } from "services/dtos/mail";

async function sendMail({ type, data }: any): Promise<ApiResponse> {
  try {
    let response;
    const headers: any = {};
    let dataRequest: any = {
      type,
      ...data,
    };

    // Condição para enviar como FormData
    if (type === "work_whit_us") {
      const formData = new FormData();
      formData.append("type", type);
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      headers["Content-Type"] = "multipart/form-data";

      dataRequest = formData;
    }

    response = await request.post(
      `${Config.api_email}/email/send.php`,
      dataRequest,
      { headers }
    );

    return {
      status: true,
      data: response.data,
    };
  } catch (error: any) {
    return {
      status: false,
      data: error.response.data as ResponseError,
    };
  }
}

const actions = {
  sendMail,
};

export default actions;


import { Grid } from "@mui/material";
import { useLocation } from "react-router";
import { StyledContainer } from "./styles";

export default function Header() {
  const location = useLocation();
  return (
    <StyledContainer>
      <Grid container py={2} px={1}>
        {location.pathname === "/localizacao" && (
          <img
            src="/images/logo-white.png"
            alt="logo"
            width={109}
            height={57}
          />
        )}
      </Grid>
    </StyledContainer>
  );
}

const KEY_PREFIX_LOCAL = "@ITOP-";

class LocalStorage {
  static set = (key: string, value: any) =>
    localStorage.setItem(KEY_PREFIX_LOCAL.concat(key), JSON.stringify(value));

  static get = (key: string) => {
    const item = localStorage.getItem(KEY_PREFIX_LOCAL.concat(key));
    return item ? JSON.parse(item) : null;
  };
}

export default LocalStorage;


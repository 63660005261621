import styled from "styled-components";

export const Title = styled.div`
  color: #FF7C06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  color: #C0C0C0;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1199px) {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const TextList = styled.div`
  color: #C0C0C0;
  font-size: 16px;
  font-weight: 200;
  padding-bottom: 0.5px;

  @media (max-width: 1199px) {
    font-size: 14px;
    font-weight: 200;
  }
`;

import styled from "styled-components";
import { TextField, Button, Box } from "@mui/material";

export const Title = styled.div`
  text-align: center;
  color: #ff7c06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }
`;

export const Description = styled.div`
  color: #ffffff;
  text-alight: center;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1200px) {
    text-alight: center;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const BottomText = styled.div`
  color: #ffffff;
  text-alight: center;
  font-size: 16px;
  font-weight: 200;

  @media (max-width: 1200px) {
    text-alight: center;
    font-size: 14px;
    font-weight: 200;
  }
`;

export const ColoredTitle = styled.span`
  color: #ff7c06;
  font-weight: 200;
`;

export const PageChip = styled(Box)`
  font-weight: 600;
  width: 135px;
  height: 40px;
  border: solid 1px #ff7c06;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
`;

export const UploadBox = styled(Box)`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 144px;
  background-color: #fbeada;
  border: 2px dashed #ff7c06;
  border-radius: 8px;
  cursor: pointer;
`;

export const UploadText = styled.div`
  color: #2d3e91;
  font-weight: 800;
  font-size: 18px;
  padding: 0px 25px;
`;

export const TypeUploadText = styled.div`
  color: #2e2e2e;
  font-weight: 400;
  font-size: 16px;
  padding-top: 8px;
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #ff7c06;
    text-transform: none;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 800;
    border-radius: 30px;
    width: 100%;
    color: black;
    height: 55px;

    &:hover {
      background-color: #f9963d;
      color: black;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    background-color: rgb(232, 241, 250);
    border-radius: 8px;
    &.Mui-focused fieldset {
      border-color: #ff7c06;
    }
  }
  & .MuiInputBase-input {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  & .MuiInputBase-input::placeholder {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2e2e2e;
    opacity: 60%;
  }
`;


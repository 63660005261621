import LocalStorage from "helpers/LocalStorage";
import carouselPlans from "data/carousel-plans";
import { checkString } from "helpers/Utils";

function getCarousel() {
  const nullCity = {city: '', uf: ''}
  const logguedCity = LocalStorage.get("city") || nullCity;

  return carouselPlans.filter(
    (item) =>
      item.cities.find(
        (city) =>
          checkString(city.city, logguedCity.city) &&
          checkString(city.uf, logguedCity.uf)
      ) || item.cities.length === 0
  );
}

const actions = {
  getCarousel,
};

export default actions;


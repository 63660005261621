import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import Config from "data/config";
import { useEffect, useState } from "react";
import carouselPlans from "data/carousel-plans";
import { carousel } from "services";
import { StyledReactPlayer } from "./styles";
import Slider from "react-slick";

export default function CarouselPlans() {
  const isDesktop = useMediaQuery("(min-width:769px)");
  const [plans, setPlans] = useState(carouselPlans);
  const [activeCarousel, setActiveCarousel] = useState(0);

  const openPlan = (plan: any) => {
    let link = plan.link;
    if (!link) {
      link = createLinkWhatsapp(
        Config.carousel.whatsapp.number,
        Config.carousel.whatsapp.message.split("[TITLE]").join(plan.title)
      );
    }
    openLink(link, "_blank");
  };

  useEffect(() => {
    setPlans(carousel.getCarousel());
  }, []);

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{
          ...style,
          width: "50px",
          height: "50px",
          marginRight: "40px",
          zIndex: 5,
        }}
        onClick={onClick}
        alt="arrow-next"
        src="images/icons/arrow-next.png"
      />
    );
  }

  function SampleBackArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        style={{
          ...style,
          width: "50px",
          height: "50px",
          marginLeft: "40px",
          zIndex: 5,
        }}
        onClick={onClick}
        alt="arrow-next"
        src="images/icons/arrow-back.png"
      />
    );
  }
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleBackArrow />,
    afterChange: (current: number) => setActiveCarousel(current),
  };

  return (
    <Stack
      sx={{
        backgroundColor: "#2E2E2E",
        paddingTop: "63px",
        paddingBottom: "20px",
      }}
    >
      <Grid
        display="flex"
        container
        justifyContent={"center"}
        textAlign="center"
      >
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
          <Box
            className="slider-container"
            sx={{ cursor: "pointer", borderRadius: "25px" }}
          >
            {plans.length > 1 ? (
              <Slider {...settings}>
                {plans.map((plan: any, idx: number) =>
                  !plan.video ? (
                    <img
                      key={idx}
                      alt={plan.title || idx.toString()}
                      src={isDesktop ? plan.imgDesktop : plan.imgMobile}
                      onClick={() => openPlan(plan)}
                    />
                  ) : (
                    <StyledReactPlayer
                      controls={true}
                      loop={true}
                      key={idx}
                      playing={activeCarousel === idx}
                      muted={true}
                      url={plan.video}
                    />
                  )
                )}
              </Slider>
            ) : (
              <div style={{ display: plans[0] ? "initial" : "none" }}>
                <img
                  alt={plans[0] ? plans[0].title : "Promoção"}
                  src={plans[0] ? isDesktop ? plans[0].imgDesktop : plans[0].imgMobile : ''}
                  style={{ height: "100%", width: "100%", cursor: "pointer", borderRadius: '25px' }}
                  onClick={() => openPlan(plans[0])}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

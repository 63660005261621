import request from "helpers/Request";
import { ApiResponse, ResponseError } from "services/dtos/mail";

async function getAddress(cep: string): Promise<ApiResponse> {
  try {
    const response = await request.get(`https://viacep.com.br/ws/${cep}/json/`);

    return {
      status: true,
      data: response.data,
    };
  } catch (error: any) {
    return {
      status: false,
      data: error.response.data as ResponseError,
    };
  }
}

const actions = {
  getAddress,
};

export default actions;


import { Paper, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Label, ResponsiveIcon } from "./styles";

type PropsCardDarkCard = {
  label: string;
  icon: string;
  children: JSX.Element;
};

export default function DarkCard({ label, children, icon }: PropsCardDarkCard) {
  const Item = styled(Paper)(() => ({
    backgroundColor: "#282828",
    color: "#fff",
    textAlign: "center",
    flexGrow: 1,
    flexBasis: "calc(100% / 4)",
    borderRadius: "15px",
  }));

  return (
    <Item elevation={0}>
      <CardContent>
        <div style={{margin: '15px 0px'}}>
          <ResponsiveIcon src={icon} alt={icon} />
        </div>
        <Label>{label}</Label>
        {children}
      </CardContent>
    </Item>
  );
}

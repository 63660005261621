import styled from "styled-components";
import {
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonClasses,
  toggleButtonGroupClasses,
} from "@mui/material";

export const StyledPaper = styled(Paper)`
  background-color: #424242 !important;
  border-radius: 10px !important;
  width: max-content;
  text-align: center;
  padding: 5px 7px;
  margin: 38px 25px;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & .${toggleButtonGroupClasses.grouped} {
    margin: 5px;
    border: 0;
    border-radius: 10px;

    &.${toggleButtonGroupClasses.disabled} {
      border: 0;
    }
  }

  &
    .${toggleButtonGroupClasses.middleButton},
    &
    .${toggleButtonGroupClasses.lastButton} {
    margin-left: -1px;
    border-left: 1px solid transparent;
  }
`;

export const StyledToggleButton = styled(ToggleButton)`
  &.${toggleButtonClasses.root} {
    color: #c0c0c0 !important;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 18px;

    &.${toggleButtonClasses.selected} {
      background-color: #ff7c06 !important;
      color: black !important;
      border-radius: 10px;
    }
  }
`;


import { Box, Stack, Tab } from "@mui/material";
import { StyledBox, StyledTabs } from "./styles";
import CardPlan from "components/CardPlan";
import { iPlanCategory } from "data/interfaces/plans";
import { isMobile } from 'react-device-detect';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      spacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 1 }}
      direction={{
        xs: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      useFlexGap
      flexWrap="wrap"
      role="tabpanel"
      justifyContent="center"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Stack>
  );
}

type PropsTabsPlans = {
  typePlan: number;
  typePersonId: number;
  allPlans: iPlanCategory[];
  onChangePlan: (event: any) => void;
};

export default function TabsPlans({
  allPlans,
  typePlan,
  onChangePlan,
  typePersonId,
}: PropsTabsPlans) {
  const handleChangePlan = (_event: React.SyntheticEvent, newValue: string) => {
    onChangePlan(newValue);
  };

  return (
    <Stack>
      <Box display="flex" justifyContent={"center"}>
        <StyledTabs
          value={typePlan}
          onChange={handleChangePlan}
          variant="scrollable"
          allowScrollButtonsMobile={isMobile ? false : true}
          scrollButtons
        >
          {allPlans[typePersonId].plans.map((plan, index) => (
            <Tab wrapped={false} key={index} value={index} label={plan.title} />
          ))}
        </StyledTabs>
      </Box>
      <StyledBox display="flex" justifyContent={"center"}>
        {allPlans[typePersonId].plans.map((plans, index) => (
          <TabPanel key={index} value={typePlan} index={index}>
            {plans.plans.map((plan, index) => (
              <CardPlan key={index} plan={plan}/>
            ))}
          </TabPanel>
        ))}
      </StyledBox>
    </Stack>
  );
}


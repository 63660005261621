import styled from "styled-components";

export const Title = styled.div`
  color: #FF7C06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const Description = styled.div`
  color: #C0C0C0;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
    padding: 25px 25px;

  @media (max-width: 1199px) {
    font-size: 14px;
    font-weight: 400;
  }
`;

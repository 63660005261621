import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, Stack } from "@mui/material";
import {
  StyledFooterItem,
  StyledFooterSubItem,
  StyledFooter,
  StyledSubItemBox,
} from "./styles";
import Config from "data/config";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";

export default function Footer() {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const year = new Date().getFullYear();
  const whatsappLink = createLinkWhatsapp(Config.contact.whatsapp.number, Config.contact.whatsapp.message)
  const linksUteis = [
    { title: "Área do cliente", link: "/login" },
    { title: "Ouvidoria", link: "/ouvidoria" },
    { title: "Segunda via de fatura", link: "/login" },
    { title: "Desbloqueio cortesia", link: "/login" },
    { title: "Trabalhe conosco", link: "/trabalhe-conosco" },
    { title: "Acesso Skeelo", link: "/login" },
    { title: "Acesso Conta Outra Vez", link: "/login" },
    { title: "Portal do assinante", link: "/login" },
  ];

  return (
    <Box py={4} sx={StyledFooter}>
      <Grid
        container
        justifyContent="center"
        textAlign={{
          xs: "initial",
          sm: "initial",
          md: isDesktop ? "center" : "initial",
          lg: "center",
        }}
      >
        <Grid item xs={10} sm={7.5} md={isDesktop ? 3 : 6.5} lg={3} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: "initial",
              lg: "center",
            }}
          >
            <img
              src="/images/logo-black.png"
              alt="logo"
              width={109}
              height={57}
            />
            <StyledFooterSubItem
              style={{
                cursor: 'default',
                paddingTop: isDesktop ? "30px" : "0px",
              }}
            >
              Copyright © {year} Itop Telecom
            </StyledFooterSubItem>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7.5} md={isDesktop ? 3 : 6.5} lg={2.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isDesktop ? "center" : "initial",
              lg: "center",
            }}
          >
            <StyledFooterItem>Fale Conosco</StyledFooterItem>
            <StyledSubItemBox>
              <StyledFooterSubItem>
                <Box
                  display="inline-flex"
                  gap={1}
                  onClick={() => openLink(whatsappLink, "_blank")}
                >
                  <img
                    src="/images/icons/whatsapp.png"
                    alt="icon-facebook"
                    width="23px"
                  />
                  <p>{Config.contact.whatsapp.number}</p>
                </Box>
              </StyledFooterSubItem>
              <StyledFooterSubItem>
                <Box
                  display="inline-flex"
                  gap={1}
                  onClick={() =>
                    openLink(`mailto:${Config.contact.email}`, "_blank")
                  }
                >
                  <img
                    src="/images/icons/headphone.png"
                    alt="icon-facebook"
                    width="23px"
                  />
                  <p>{Config.contact.email}</p>
                </Box>
              </StyledFooterSubItem>
            </StyledSubItemBox>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7.5} md={isDesktop ? 3 : 6.5} lg={2.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isDesktop ? "center" : "initial",
              lg: "center",
            }}
          >
            <StyledFooterItem>Links úteis</StyledFooterItem>
            <StyledSubItemBox>
              {linksUteis.map(({ title, link }, key) => (
                <StyledFooterSubItem key={key} onClick={() => openLink(link)}>
                  {title}
                </StyledFooterSubItem>
              ))}
            </StyledSubItemBox>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7.5} md={isDesktop ? 3 : 6.5} lg={3.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isDesktop ? "center" : "initial",
              lg: "center",
            }}
          >
            <StyledFooterItem>Siga nas redes</StyledFooterItem>
            <StyledSubItemBox>
              <StyledFooterSubItem>
                <Stack direction="row" spacing={2}>
                  <a
                    href="https://www.facebook.com/itoptelecom"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/icons/facebook.png"
                      alt="icon-facebook"
                      width="23px"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/itoptelecom/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/icons/instagram.png"
                      alt="icon-instagram"
                      width="23px"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/itop-telecom1/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/images/icons/linkedin.png"
                      alt="icon-linkedin"
                      width="23px"
                    />
                  </a>
                </Stack>
              </StyledFooterSubItem>
            </StyledSubItemBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}


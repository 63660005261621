import "react-toastify/dist/ReactToastify.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import { Title, StyledButton, StyledTextField } from "./styles";
import { styled } from "@mui/system";
import Config from "data/config";
import { address, mail } from "services";
import SmallStyled from "components/SmallStyled";
import { ToastContainer, toast } from "react-toastify";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";

interface iFormEquipament {
  name: string;
  cep: string;
  address: string;
  address_complement: string;
  address_number: string;
  pickup_time: string;
}

export default function ReturnForm() {
  const StyledSelect = styled(Select)({
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "rgb(232, 241, 250)",
    "& .MuiSelect-outlined": {
      backgroundColor: "rgb(232, 241, 250)",
      borderRadius: "8px",
      borderWidth: "2px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "#ff7c06",
      borderRadius: "8px",
    },

    "& .MuiInputBase-input": {
      fontFamily: "'Mulish', sans-serif",
      fontWeight: "400",
      fontSize: "16px",
      borderRadius: "8px",
    },
  });

  const options: any = Config.pages.returnEquipment.form.hours;
  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<iFormEquipament>();
  const onSubmit: SubmitHandler<iFormEquipament> = async (data) => {
    try {
      const resonse = await mail.sendMail({
        type: "return_equipament",
        data,
      });

      if (resonse.status) {
        setValue("name", "");
        setValue("cep", "");
        setValue("address", "");
        setValue("address_complement", "");
        setValue("address_number", "");
        setValue("pickup_time", "");
        toast("Solicitação de devolução realizada com sucesso!", {
          type: "success",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error: any) {
      toast("Erro ao solicitar devolução. Redirecionando para o Whatsapp!", {
        type: "error",
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        const message = `Olá acessei o website e quero realizar a devolução de equipamento, esses são meus dados:
        \n
        \nNome *${data.name}*
        \nCEP: *${data.cep || ""}*
        \nEndereço: *${data.address || ""}*
        \nNúmero: *${data.address_number || ""}*
        \nComplemento: *${data.address_complement || ""}*
        \nHorário de coleta: *${data.pickup_time || ""}*`;

        const link = createLinkWhatsapp(
          Config.contact.whatsapp.number,
          encodeURIComponent(message)
        );

        openLink(link, "_blank");
      }, 3000);
    }
  };

  const fetchAddressFromCep = async (cep: string) => {
    if (cep.length === 9) {
      try {
        const response = await address.getAddress(cep);
        if (response && response.status) {
          setValue(
            "address",
            `${response.data.logradouro || ""}, ${response.data.bairro || ""}`
          );
        } else {
          // Handle errors or invalid CEP
          setValue("address", "");
        }
      } catch (error) {
        console.error("Error fetching address:", error);
      }
    }
  };

  const applyCepMask = (value: string) => {
    return value
      .replace(/\D/g, "") // Remove tudo que não é dígito
      .replace(/(\d{5})(\d)/, "$1-$2") // Adiciona um hífen entre o quinto e o sexto dígitos
      .slice(0, 9); // Limita o tamanho a 9 caracteres (formato 99999-999)
  };

  return (
    <Box py={9} bgcolor="#2E2E2E">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>Solicite a devolução</Title>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid pt={3} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="Nome completo do assinante"
              {...register("name", { required: true, minLength: 5 })}
            />
            {errors.name?.type === "required" && (
              <SmallStyled>Nome é obrigatório</SmallStyled>
            )}
            {errors.name?.type === "minLength" && (
              <SmallStyled>
                Nome deve possuir no mínimo 5 caractéres
              </SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <Controller
              name="cep"
              control={control}
              defaultValue=""
              rules={{
                required: "CEP é obrigatório",
                minLength: {
                  value: 9,
                  message: "CEP deve possuir 9 caracteres",
                },
              }}
              render={({ field }) => (
                <StyledTextField
                  {...field}
                  placeholder="CEP"
                  value={applyCepMask(field.value)}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    fetchAddressFromCep(applyCepMask(e.target.value));
                  }}
                  error={!!errors.cep}
                  helperText={errors.cep ? errors.cep.message : ""}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="Rua, Avenida, Travessa"
              {...register("address", { required: true, minLength: 5 })}
            />
            {errors.address?.type === "required" && (
              <SmallStyled>Rua é obrigatório</SmallStyled>
            )}
            {errors.address?.type === "minLength" && (
              <SmallStyled>Rua deve possuir no mínimo 5 caractéres</SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={7} sm={6.5} md={4} lg={3} xl={2.5} item px={1}>
            <StyledTextField placeholder="Complemento" />
            {errors.address_complement?.type === "required" && (
              <SmallStyled>Complemento é obrigatório</SmallStyled>
            )}
            {errors.address_complement?.type === "minLength" && (
              <SmallStyled>
                Complemento deve possuir no mínimo 5 caractéres
              </SmallStyled>
            )}
          </Grid>
          <Grid xs={4} sm={2.5} md={2} lg={1.5} xl={1} item px={1}>
            <StyledTextField
              placeholder="Número"
              {...register("address_number", {
                required: true,
              })}
            />
            {errors.address_number?.type === "required" && (
              <SmallStyled>Número é obrigatório</SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="pickup_time"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  inputProps={{ MenuProps: { disableScrollLock: true } }}
                  displayEmpty
                  variant="outlined"
                  defaultValue=""
                  renderValue={(selected: any) => {
                    if (selected.length === 0) {
                      return (
                        <span style={{ color: "#2E2E2E", opacity: "60%" }}>
                          Horário de coleta
                        </span>
                      );
                    }
                    return selected;
                  }}
                  value={value || ""}
                  onChange={onChange}
                >
                  {options.map((it: any, index: number) => (
                    <MenuItem key={index} value={it}>
                      {it}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />
            {errors.pickup_time?.type === "required" && (
              <SmallStyled>Horário de coleta é obrigatório</SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledButton type="submit">Solicitar devolução</StyledButton>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </Box>
  );
}


import styled from "styled-components";

export const StyledIcon = styled.div`
  position: fixed;
  right: 26px;
  bottom: 26px;
  cursor: pointer;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.5));
`;

// Estilize o Tooltip usando withStyles
export const StyledTooltip = {
  tooltip: {
    sx: {
      borderRadius: "15px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      padding: "12px",
      border: "1px solid #FF7C06",
      color: "#fff",
      fontSize: 16, // Ajuste conforme necessário
    },
  },
  arrow: {
    sx: {
      fontSize: 20,
      color: "rgba(0, 0, 0, 0.3)",
      "&::before": {
        border: "1px solid #FF7C06",
        borderWidth: "0 1px 1px 1px",
      },
    },
  },
};


import { Grid, Box } from "@mui/material";
import { Title, ColoredTitle, SubTitle, StyledBox } from "./styles";

export default function Initial() {
  return (
    <StyledBox>
      <Grid
        pt={{ xs: "185px", sm: "185px", md: "200px", lg: "200px", xl: "200px" }}
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={12}
          xl={12}
          px={1}
          pt={{ xs: 1.5, sm: 1.5, md: 2, lg: 6.5, xl: 6.5 }}
        >
          <Title>
            <ColoredTitle>Devolução</ColoredTitle> de equipamentos
          </Title>
        </Grid>
        <Grid
          item
          pt={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          xs={11}
          sm={8}
          md={7}
          lg={6.5}
          xl={6}
          px={{ xs: 0, sm: 0.5, md: 1, lg: 1.5, xl: 6 }}
        >
          <SubTitle>
            Solicite a devolução do seu equipamento preenchendo o formulário
            abaixo. Nossa equipe entrará em contato para realizar a coleta.
          </SubTitle>
          <Box pt={15} textAlign='center'>
          <img
            src="/images/arrow.png"
            width={45}
            height={45}
            alt="arrow icon"
          />
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
}

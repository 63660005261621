import { Box } from "@mui/material";
import Initial from "../../components/WorkWhitUs/Initial/index";
import WorkWhitUsForm from "../../components/WorkWhitUs/Form/index";
import WhyWorkWhitUs from "../../components/WorkWhitUs/WhyWorkWhitUs/index";

export default function WorkWhitUs() {
  return (
    <Box width="100%" height="100%" bgcolor="#2E2E2E">
      <Initial />
      <WhyWorkWhitUs />
      <WorkWhitUsForm />
    </Box>
  );
}

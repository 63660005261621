import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledInput = styled(TextField)`
  .MuiInputBase-root {
    color: #fff;
    font-size: 18px;
  }
  .MuiInputLabel-root {
    padding-left: 15px;
    color: #fff;
  }
  .MuiFormLabel-root {
    color: #fff !important;
  }
  .MuiOutlinedInput-notchedOutline {
    background-color: rgba(0, 0, 0, 0.15); /* Cor de fundo da lista */
    border: 1px solid #ff7c06 !important; /* Borda do menu */
    border-radius: 65.8678px !important;
    color: #fff;
    box-shadow: 0px 5px 30px rgba(111, 60, 15, 0.5) !important;
  }
`;


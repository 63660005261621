import { Outlet } from "react-router";
import Header from "./Header";
import { Stack } from "@mui/material";

export default function MainLayout() {
  return (
    <Stack>
      <Header />
      <Outlet />
    </Stack>
  );
}


import { Box, Grid } from "@mui/material";
import { Title, SubTitle } from "./styles";

export default function Information() {
  return (
    <Box
      sx={{
        backgroundImage: "linear-gradient(180deg, #FF7C06 0%, #F9963D 101.45%)",
      }}
    >
      <Grid display="flex" container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5} textAlign="center">
          <Grid
            textAlign={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
              xl: "left",
            }}
          >
            <Grid
              container
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "",
                xl: "",
              }}
              pt={{ xs: 5, sm: 5, md: 5, lg: 7, xl: 7 }}
            >
              <Grid item xs={10} sm={6.5} md={6} lg={9} xl={8.5}>
                <Title>Compromisso com a sua satisfação</Title>
                <Box py={{ xs: 5, sm: 5, md: 5, lg: 6, xl: 6 }}>
                  <SubTitle>
                    Na Itop Telecom, estamos comprometidos em proporcionar a
                    você a melhor experiência possível. A Ouvidoria é uma
                    extensão desse compromisso, garantindo que suas preocupações
                    sejam tratadas com seriedade e respeito.
                  </SubTitle>
                  <SubTitle style={{ marginTop: "30px" }}>
                    Contamos com sua participação para continuarmos aprimorando
                    nossos serviços e atendendo suas necessidades da melhor
                    forma possível.
                  </SubTitle>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid display={{xs: 'none', sm: 'initial', md: 'initial', lg: 'none', xl: 'none'}} sx={{ order: { xs: -1, sm: -1, md: -1, lg: 1 }, }} item sm={1} md={2} />
        <Grid
          sx={{ order: { xs: -1, sm: -1, md: -1, lg: 1 } }}
          item
          xs={12}
          sm={11}
          md={10}
          lg={6.5}
          xl={7}
          mb="-5px"
          textAlign="right"
        >
          <img src="/images/internalAffairs/information.png" alt="banner" />
        </Grid>
      </Grid>
    </Box>
  );
}

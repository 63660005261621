import Config from "data/config";
import { StyledIcon, StyledTooltip } from "./styles";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import { Tooltip } from "@mui/material";

export default function ChatButton() {
  const whatsappLink = createLinkWhatsapp(
    Config.contact.whatsapp.number,
    Config.contact.whatsapp.message
  );

  return (
    <Tooltip
      title="Contrate pelo WhatsApp"
      placement="bottom-start"
      componentsProps={StyledTooltip}
      arrow
    >
      <StyledIcon>
        <div onClick={() => openLink(whatsappLink, "_blank")}>
          <img
            src="/images/icons/whatsapp-circle.png"
            alt="icon-linkedin"
            width="75px"
          />
        </div>
      </StyledIcon>
    </Tooltip>
  );
}


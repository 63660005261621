import styled from "styled-components";
import { Box, Tabs, tabClasses, tabsClasses } from "@mui/material";

export const StyledTabs = styled(Tabs)`
  & .${tabClasses.root} {
    color: #fff !important;
    text-transform: capitalize !important;
    border-bottom: 0.647405px solid rgba(255, 255, 255, 0.15); /* Borda para os itens do menu */
    padding: 0px 40px;
    font-size: 18px;

    @media (max-width: 1199px) {
      font-size: 14px;
      padding: 0px 22px;
    }

    &.Mui-selected {
      color: #ff7c06 !important;
    }
  }
  & .${tabsClasses.indicator} {
    background-color: #ff7c06 !important;
  }

    & .MuiTabs-scrollButtons {
    color: #ff7c06; 

    &:disabled {
      opacity: 0.3;
  }
`;

export const StyledBox = styled(Box)`
  padding: 22px;

  @media (max-width: 1199px) {
    padding: 22px;
  }
`;


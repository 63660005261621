import { Box, Grid, Stack } from "@mui/material";
import { Title, Description } from "./styles";
import DarkCard from "components/DarkCard";

export default function WhyContact() {
  const differences: any = [
    {
      label: "Resolução de Problemas",
      icon: "/images/internalAffairs/whyContact/ic-prob.png",
      description:
        "Se você já tentou resolver um problema com nossos canais de atendimento padrão e não obteve sucesso, a Ouvidoria está aqui para ajudar. Nossa equipe dedicada irá investigar a fundo sua questão e buscar uma solução satisfatória.",
    },
    {
      label: "Feedback Construtivo",
      icon: "/images/internalAffairs/whyContact/ic-feedb.png",
      description:
        "Valorizamos seu feedback para continuamente melhorar nossos produtos e serviços. Seja elogios, sugestões ou críticas construtivas, queremos ouvir o que você tem a dizer para aprimorarmos sua experiência.",
    },
    {
      label: "Confidencialidade",
      icon: "/images/internalAffairs/whyContact/ic-conf.png",
      description:
        "Todas as informações compartilhadas conosco através da Ouvidoria são tratadas com a máxima confidencialidade. Nosso compromisso é proteger sua privacidade e seus dados pessoais.",
    },
  ];

  return (
    <Box
      py={10}
      sx={{
        backgroundColor: "#2E2E2E",
      }}
    >
      <Grid display="flex" container justifyContent={"center"}>
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} textAlign='center' pb={5}>
          <Title>Por que entrar em contato?</Title>
        </Grid>
        <Grid item xs={11} sm={8} md={6.5} lg={10.5} xl={10.5}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            useFlexGap
            flexWrap="wrap"
          >
            {differences.map((it: any, index: number) => (
              <DarkCard icon={it.icon} label={it.label} key={index}>
                <Description>{it.description}</Description>
              </DarkCard>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

import { iPlanDetails } from "data/interfaces/plans";
import Config from "data/config";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";
import {
  Amount,
  AmountBox,
  AmountRecurrence,
  Benefit,
  Speed,
  StyledButton,
  StyledCard,
  StyledList,
  StyledListItem,
  Title,
} from "./styles";

type PropsCardPlan = {
  plan: iPlanDetails;
};

interface iSizes {
  icon: string;
  size: string;
}

export default function CardPlan({ plan }: PropsCardPlan) {
  const formatAmount = (amount: number): string => {
    return amount.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const openBuyPlan = (plan: iPlanDetails) => {
    const message = `Olá acessei o website e tenho interesse em contratar:\n\nPlano: *${
      plan.title
    }*\nValor: *${formatAmount(plan.amount)}*`;

    const link = createLinkWhatsapp(Config.contact.whatsapp.number, encodeURIComponent(message));

    openLink(link, "_blank");
  };

  const sizeIcon = (iconName: string) => {
    let defaultSize = "23px";
    const sizes: iSizes[] = [
      {
        icon: "mobile",
        size: "10px",
      },
      {
        icon: "fixo",
        size: "16px",
      },
      {
        icon: "contaoutravez",
        size: "30px",
      },
      {
        icon: "telecine",
        size: "35px",
      },
      {
        icon: "skeelo",
        size: "55px",
      },
      {
        icon: "globoplay",
        size: "55px",
      },
      {
        icon: "premier",
        size: "55px",
      },
      {
        icon: "deezer",
        size: "60px",
      },
      {
        icon: "max",
        size: "52px",
      },
      {
        icon: "ubook",
        size: "40px",
      },
    ];
    const size = sizes.find((icon) => iconName.includes(icon.icon));

    return size?.size || defaultSize;
  };
  return (
    <StyledCard>
      <Title>{plan.title}</Title>
      <Speed>
        {plan.speed} {plan.speedType} de velocidade
      </Speed>
      <StyledList>
        {plan.benefits.map((benefit, index) => (
          <StyledListItem key={index}>
            <img
              src={`/images/icons/plans/${benefit.icon}`}
              alt="icon-linkedin"
              width={sizeIcon(benefit.icon)}
            />
            <Benefit>{benefit.title}</Benefit>
          </StyledListItem>
        ))}
      </StyledList>
      <AmountBox>
        <Amount>{formatAmount(plan.amount)}</Amount>
        <AmountRecurrence>{plan.recurrence}</AmountRecurrence>
      </AmountBox>
      <StyledButton
        variant="contained"
        size="large"
        onClick={() => openBuyPlan(plan)}
      >
        Quero contratar
      </StyledButton>
    </StyledCard>
  );
}

import {
  StyledPaper,
  StyledToggleButton,
  StyledToggleButtonGroup,
} from "./styles";
import Plans from "data/plans";

type PropsTooglePerson = {
  onChange: (event: any) => void;
  typePerson: string;
};

export default function TooglePerson({
  typePerson,
  onChange,
}: PropsTooglePerson) {
  const handleChangeTypePerson = (event: any) => {
    onChange(event);
  };

  return (
    <StyledPaper elevation={0}>
      <StyledToggleButtonGroup
        value={typePerson}
        exclusive
        onChange={handleChangeTypePerson}
        size="small"
      >
        {Plans.map((plans, index) => (
          <StyledToggleButton
            id={index.toString()}
            key={plans.title}
            value={plans.title}
            aria-label="centered"
          >
            {plans.title}
          </StyledToggleButton>
        ))}
      </StyledToggleButtonGroup>
    </StyledPaper>
  );
}


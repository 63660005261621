import styled from "styled-components";

export const Title = styled.div`
  color: #ff7c06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  color: #c0c0c0;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1199px) {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const ResponsiveIcon = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 0px) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 600px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 960px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 1200px) {
    width: 58px;
    height: 58px;
  }

  @media (min-width: 1920px) {
    width: 58px;
    height: 58px;
  }
`;


import { Box, List, ListItem } from "@mui/material";
import styled from "styled-components";

export const StyledListBox = styled(Box)`
  background-color: rgba(0, 0, 0, 0.15); /* Cor de fundo da lista */
  border: 1px solid #ff7c06;
  border-radius: 20px;
  margin-top: 14px !important;
  width: 100%;
  padding: 20px 16px !important;
`;

export const StyledList = styled(List)`
  overflow: auto;
  max-height: 200px;
  padding: 9px 25px !important;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 0.3em; /* Largura da barra de rolagem vertical */
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #ff7c06;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }
`;

export const StyledListItem = styled(ListItem)`
  border-bottom: 0.647405px solid rgba(255, 255, 255, 0.15); /* Borda para os itens do menu */
  color: #fff;
  font-size: 18px;
  padding: 5px 0px !important;
`;


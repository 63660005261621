import styled from "styled-components";
import Paper from "@mui/material/Paper";

export const Title = styled.div`
  color: #000000;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1199px) {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const ServicesCard = styled(Paper)`
  background-color: #fff;
  flex-grow: 1;
  width: 110px;
  height: 110px;

  @media (min-width: 600px) {
    width: 115px;
    height: 115px;
  }

  @media (min-width: 960px) {
    width: 115px;
    height: 115px;
  }

  @media (min-width: 1200px) {
    width: 150px;
    height: 150px;
  }

  @media (min-width: 1920px) {
    width: 150px;
    height: 150px;
  }
`;

export const ResponsiveIcon = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 0px) {
    width: 40px;
    height: 40px;
  }

  @media (min-width: 600px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 960px) {
    width: 45px;
    height: 45px;
  }

  @media (min-width: 1200px) {
    width: 58px;
    height: 58px;
  }

  @media (min-width: 1920px) {
    width: 58px;
    height: 58px;
  }
`;

export const Label = styled.div`
  text-alight: start;
  font-weight: 600;
  font-size: 13px;

  @media (min-width: 0px) {
    font-size: 12px;
    padding: 0px 1px;
  }

  @media (min-width: 600px) {
    font-size: 13px;
    padding: 0px 5px;
  }

  @media (min-width: 960px) {
    font-size: 13px;
    padding: 0px 5px;
  }

  @media (min-width: 1200px) {
    font-size: 15px;
    padding: 10px 5px;
  }

  @media (min-width: 1920px) {
    font-size: 15px;
    padding: 10px 5px;
  }
`;

import styled from "styled-components";

export const Label = styled.div`
  color: #FFFF;
  font-size: 24px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 22px;
    font-weight: 700;
  }
`;

export const ResponsiveIcon = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 0px) {
    width: 85px;
    height: 85px;
  }

  @media (min-width: 600px) {
    width: 85px;
    height: 85px;
  }

  @media (min-width: 960px) {
    width: 85px;
    height: 85px;
  }

  @media (min-width: 1200px) {
    width: 95px;
    height: 95px;
  }

  @media (min-width: 1920px) {
    width: 95px;
    height: 95px;
  }
`;


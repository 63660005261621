import { checkString } from "helpers/Utils";
import AllCities from "data/cities";
import Config from "data/config";

function getCities(): any {
  const citiesEnabled = Config.pages.location.itopCities;

  return AllCities.filter((city) =>
    citiesEnabled.find(
      (item) =>
        checkString(city.city, item.city) && checkString(city.uf, item.uf)
    )
  );
}

const actions = {
  getCities,
};

export default actions;


import styled from "styled-components";

export const StyledFooter = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
  backgroundImage: "linear-gradient(180deg, #FF7C06 0%, #F9963D 101.45%)",
};

export const StyledFooterItem = styled.div`
  text-align: initial;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 800;

  @media (max-width: 1200px) {
    text-align: initial;
    margin-top: 30px;
  }
`;
export const StyledSubItemBox = styled.div`
  margin-top: 26px;
  @media (max-width: 1200px) {
    margin-top: 10px;
  }
`;
export const StyledFooterSubItem = styled.div`
  text-align: initial;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 12px;
  cursor: pointer;
`;


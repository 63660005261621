import CloseIcon from "@mui/icons-material/Close";
import NavMenu from "../Menu";
import { Box, Grid, IconButton, SwipeableDrawer } from "@mui/material";

type PropsDrawer = {
  open: boolean;
  handleDrawer: (value: boolean) => void;
};

export default function Drawer({ open, handleDrawer }: PropsDrawer) {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => handleDrawer(false)}
      onOpen={() => handleDrawer(true)}
      PaperProps={{ sx: { width: "300px" } }}
    >
      <Box height="100%" bgcolor="#2E2E2E">
        <Grid container px={1}>
          <Grid item xs={12} sm={12} md={12} textAlign="right" pt={7}>
            <IconButton onClick={() => handleDrawer(false)}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Grid>
          <Grid item xs={12} sm={12} md={12} px={4} pt={6}>
            <NavMenu click={() => handleDrawer(false)} direction="column" spacing={6} justify="initial" />
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
}
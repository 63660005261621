import { Menu } from "@mui/material";
import styled from "styled-components";

export const StyledSubItem = styled.div`
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
  margin-top: 20px !important;
`;

export const StyledMenu = styled(Menu)`
  .MuiList-root {
    background-color: #2e2e2e; /* Cor de fundo da lista */
    border: 1px solid #ff7c06; /* Borda do menu */
    border-radius: 15px; /* Radius do menu */
  }

  .MuiMenu-paper {
    border-radius: 15px; /* Radius do menu */
    margin-top: 20px;
  }

  .MuiMenuItem-root {
    border-bottom: 0.647405px solid rgba(255, 255, 255, 0.15); /* Borda para os itens do menu */
    color: #ffffff; /* Cor do texto */
    padding: 15px;
    margin: 0px 20px;
    font-size: 16px;
  }

  .MuiMenuItem-root:is(:last-child) {
    border-bottom: none; /* Linhas separadoras para todos exceto o último */
  }
`;


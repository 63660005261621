import { Grid } from "@mui/material";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import { Title, ColoredTitle, SubTitle, StyledBox, PageChip } from "./styles";

export default function Initial() {
  return (
    <StyledBox>
      <Grid
        pt={{ xs: "185px", sm: "185px", md: "200px", lg: "200px", xl: "200px" }}
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          px={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          pb={4}
        >
          <PageChip>
            <ElectricBoltOutlinedIcon
              sx={{
                fontSize: "16px",
                marginRight: "7px",
                color: "#ff7c06",
              }}
            />
            MAIS VELOCIDADE
          </PageChip>
        </Grid>
        <Grid item xs={9} sm={9} md={9} lg={12} xl={12} px={1}>
          <Title>
            A internet que <ColoredTitle>você precisa!</ColoredTitle>
          </Title>
        </Grid>
        <Grid
          item
          pt={2.5}
          xs={10}
          sm={8}
          md={7.5}
          lg={5}
          xl={4.5}
          px={{ xs: 0, sm: 0.5, md: 1, lg: 1.5, xl: 6 }}
        >
          <SubTitle>
            Confira abaixo os planos disponíveis na sua cidade ou monte o seu
            plano personalizado
          </SubTitle>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} pt={6}>
          {/* <StyledButton
            onClick={() => navigate("/monte-seu-plano")}
            variant="contained"
            size="large"
          >
            Monte seu plano
            <EastIcon
              sx={{ fontSize: "19px", marginTop: "2.5px", marginLeft: "8px" }}
            />
          </StyledButton> */}
        </Grid>
      </Grid>
    </StyledBox>
  );
}


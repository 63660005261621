import { TextList } from "./styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

type PropsCardList = {
  items: Array<any>;
};

export default function CardList({ items }: PropsCardList) {
  return (
    <List sx={{ paddingTop: "25px" }}>
      {items.map((it: any, index: number) => (
        <ListItem key={index}>
          <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
            <img
              width={17}
              height={17}
              src="/images/icons/check.png"
              alt="check icon"
            />
          </ListItemIcon>
          <TextList>{it}</TextList>
        </ListItem>
      ))}
    </List>
  );
}

import { Box, Grid, Stack } from "@mui/material";
import { Title, SubTitle, ResponsiveIcon } from "./styles";
import DarkCard from "components/DarkCard";
import CardList from "./CardList";

export default function OurDifferences() {
  const differences: any = [
    {
      label: "WI-FI 6",
      icon: "/images/differences/ic-wifi.png",
      items: [
        "Velocidade aprimorada",
        "Melhor desempenho em ambientes lotados",
        "Menor latência",
        "Eficiência energética",
      ],
    },
    {
      label: "Móvel",
      icon: "/images/differences/ic-mov.png",
      items: [
        "Internet que acumula",
        "Cobertura em todo Brasil",
        "Ligações ilimitadas",
      ],
    },
    {
      label: "Suporte Técnico",
      icon: "/images/differences/ic-sup.png",
      items: ["Atendimento Local", "Agilidade", "Pró ativo"],
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#2E2E2E",
      }}
    >
      <Grid display="flex" container justifyContent={"center"}>
        <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5} textAlign="center">
          <Grid
            textAlign={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
              xl: "left",
            }}
          >
            <Grid
              container
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "",
                xl: "",
              }}
              pt={{ xs: 3, sm: 3, md: 3, lg: 7, xl: 7 }}
            >
              <Grid item xs={11} sm={7.5} md={6} lg={9} xl={8.5}>
                <Title>Diferenciais Itop</Title>
                <Box py={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <SubTitle>
                    Tenha a velocidade da luz na palma da sua mão.
                    <br /> Assista série na melhor qualidade sem pausas
                    irritantes, jogue sem lag, navegue pelas redes sociais na
                    melhor conexão da região.
                  </SubTitle>
                </Box>
                <Box pt={{ xs: 3, sm: 3, md: 3, lg: 6, xl: 6 }}>
                  <ResponsiveIcon  src="/images/arrow.png" alt="arrow" />
                  </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid display={{xs: 'none', sm: 'initial', md: 'initial', lg: 'none', xl: 'none'}} sx={{ order: { xs: -1, sm: -1, md: -1, lg: 1 }, }} item sm={1} md={3} />
        <Grid
          sx={{ order: { xs: -1, sm: -1, md: -1, lg: 1 }, }}
          item
          xs={12}
          sm={11}
          md={9}
          lg={6.5}
          xl={7}
        >
          <img width="100%" src="/images/img-vagalume.png" alt="banner" />
        </Grid>
      </Grid>
      <Grid display="flex" container justifyContent={"center"} pt={5} pb={7}>
        <Grid item xs={11} sm={8} md={6.5} lg={10.5} xl={10.5}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction={{
              xs: "column",
              sm: "column",
              md: "column",
              lg: "row",
              xl: "row",
            }}
            useFlexGap
            flexWrap="wrap"
          >
            {differences.map((it: any, index: number) => (
              <DarkCard icon={it.icon} label={it.label} key={index}>
                <CardList items={it.items} />
              </DarkCard>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

import { Box } from "@mui/material";
import Initial from "components/InternalAffairs/Initial";
import WhyContact from "components/InternalAffairs/WhyContact";
import Information from "components/InternalAffairs/Information";
import InternalAffairsForm from "components/InternalAffairs/Form";
export default function InternalAffairs() {
  return (
    <Box width="100%" height="100%">
      <Initial />
      <WhyContact />
      <Information />
      <InternalAffairsForm />
    </Box>
  );
}

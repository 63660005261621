import { Outlet } from "react-router";
import { StyledBody, StyledContainer, StyledMain } from "./styles";
import Footer from "./Footer";
import ChatButton from "./ChatButton";
import Header from "./Header";
import { useLocation } from "react-router";

export default function MainLayout() {
  const location = useLocation()
  
  return (
    <>
      <StyledContainer>
        <StyledBody>
          <Header />
          <StyledMain>
            <Outlet />
          </StyledMain>
          <Footer />
         {location.pathname !== '/monte-seu-plano' && <ChatButton />}
        </StyledBody>
      </StyledContainer>
    </>
       
  );
}

import { Grid, Box } from "@mui/material";
import { Title, ColoredTitle, SubTitle, StyledBox } from "./styles";

export default function Initial() {
  return (
    <StyledBox>
      <Grid
        pt={{ xs: "165px", sm: "165px", md: "200px", lg: "200px", xl: "200px" }}
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={12}
          xl={12}
          px={1}
          pt={{ xs: 1.5, sm: 1.5, md: 2, lg: 6.5, xl: 6.5 }}
        >
          <Title>
            Junte-se à <ColoredTitle>nossa equipe</ColoredTitle>
          </Title>
        </Grid>
        <Grid
          item
          pt={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}
          xs={11}
          sm={8}
          md={7}
          lg={7}
          xl={6}
          px={{ xs: 0, sm: 0.5, md: 1, lg: 0, xl: 4.5 }}
        >
          <SubTitle>
            Na Itop Telecom,{" "}
            <span style={{ fontWeight: 800 }}>
              acreditamos que as pessoas são nosso maior ativo.<br/> 
            </span>
            Estamos constantemente em busca de indivíduos talentosos e
            apaixonados para se juntar à nossa equipe e ajudar a impulsionar
            nossa missão de fornecer soluções inovadoras e serviços excepcionais
            aos nossos clientes.
          </SubTitle>
          <Box
            pt={{ xs: 6, sm: 14, md: 14, lg: 14, xl: 14 }}
            textAlign="center"
          >
            <img
              src="/images/arrow.png"
              width={45}
              height={45}
              alt="arrow icon"
            />
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
}

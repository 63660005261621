import { InputAdornment } from "@mui/material";
import { StyledInput } from "./styles";
import LocalStorage from "helpers/LocalStorage";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";

type PropsInput = {
  onChange: (value: string) => void;
};

export default function InputCity({ onChange }: PropsInput) {
  const [inputCity, setInputCity] = useState("");
  const city = LocalStorage.get("city");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputCity(value);
    onChange(value);
  };

  useEffect(() => {
    if (city) {
      setInputCity(city.city);
      onChange(city.city);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <StyledInput
      id="outlined-basic"
      label="Qual a sua cidade?"
      variant="outlined"
      value={inputCity}
      fullWidth
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <KeyboardArrowUpIcon htmlColor="#fff" />
          </InputAdornment>
        ),
      }}
    />
  );
}


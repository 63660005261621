import { Grid } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Title,
  FullHeightBox,
  FullHeightImage,
  StyledTextField,
  StyledButton,
} from "./styles";
import { login } from "services";
import { ToastContainer, toast } from "react-toastify";

export default function Login() {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [formData, setFormData] = useState({ user: "", password: "" });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<any>();

  // Handler for input changes
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlerLogin = async () => {
    const response = await login.postLogin({
      user: formData.user,
      password: formData.password,
    });

    if (response.status) {
      window.open(response.redirect, "_self");
    } else {
      toast("Não foi possível entrar. Verifique suas credenciais!", {
        type: "error",
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FullHeightBox>
      <form onSubmit={handleSubmit(handlerLogin)}>
        <Grid container pb={{ xs: 5, sm: 5, md: 0, lg: 0, xl: 0 }}>
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            lg={7}
            xl={6}
            container
            sx={{
              maxHeight: "100vh",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <FullHeightImage
              src={
                !isMobile
                  ? "/images/bg-login.png"
                  : "/images/bg-login-mobile.png"
              }
              alt="img"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={6} textAlign="center">
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                pt={{ xs: 3.5, sm: 2, md: 5, lg: 5, xl: 5 }}
                textAlign="center"
              >
                <img
                  src="/images/logo-white.png"
                  alt="logo"
                  width={128}
                  height={68}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                py={{ xs: 4, sm: 4, md: 10, lg: 10, xl: 10 }}
                textAlign="center"
              >
                <Title>Faça seu login</Title>
              </Grid>
              <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5}>
                <StyledTextField
                  required
                  placeholder="Login"
                  name="user"
                  value={formData.user}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5} pt={2}>
                <StyledTextField
                  required
                  placeholder="Senha"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={11} sm={10} md={10} lg={8} xl={6.5} pt={2}>
                <StyledButton
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? "" : "Login"}
                </StyledButton>
              </Grid>
              {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              pt={{ xs: 6, sm: 5, md: 10, lg: 10, xl: 10 }}
              pb={{ xs: 3, sm: 2, md: 3, lg: 3, xl: 3 }}
              textAlign="center"
            >
              <ForgetPassword>Esqueci a senha</ForgetPassword>
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </FullHeightBox>
  );
}

import styled from "styled-components";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const Title = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 51px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 31px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
  }
`;

export const ColoredTitle = styled.span`
  color: #ff7c06;
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #2D3E91;
    text-transform: none;
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    width: 270px;
    height: 60px;

    &:hover {
      background-color: #ff7c06;
      color: black;
    }
  }
`;

export const PageChip = styled(Box)`
  font-weight: 600;
  width: 200px;
  height: 40px;
  border: solid 1px #ff7c06;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #fff;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  height: 650px;
  @media (min-width: 600px) {
    height: 650px;
  }

  @media (min-width: 960px) {
    height: 700px;
  }

  @media (min-width: 1280px) {
    height: 750px;
  }

  @media (min-width: 1920px) {
    height: 750px;
  }

  background-image: url('/images/initial.png'), linear-gradient(to bottom, rgba(15, 9, 2, 0) 10%, rgba(46, 46, 46, 1) 100%);
  background-size: cover;
  background-position: right 25% bottom 45%;
  background-blend-mode: overlay;
`;

import { Button, Card, List, ListItem } from "@mui/material";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 30px 23px;
  margin: 20px 3px;
  border-radius: 15px !important;
`;

export const Title = styled.div`
  color: #ff7c06;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 17px;
    font-weight: 700;
  }
`;

export const Speed = styled.div`
  font-size: 18px;
  font-weight: 700;
  padding-top: 30px;

  @media (max-width: 1199px) {
    font-size: 14px;
    font-weight: 700;
    padding-top: 20px;
  }
`;

export const Benefit = styled.div`
  font-size: 15px;
  font-weight: 500;
  padding-left: 14px;

  @media (max-width: 1199px) {
    font-size: 12px;
    font-weight: 500;
    padding-left: 11px;
  }
`;

export const StyledList = styled(List)`
  overflow: auto;
  padding: 9px 25px !important;
  width: 280px;
  height: 418px;
  @media (max-width: 1199px) {
    height: 400px;
  }

  /* Ocultar a barra de rolagem por padrão no desktop */
  &::-webkit-scrollbar {
    width: 0; /* Oculta a barra de rolagem */
  }

  /* Exibir a barra de rolagem ao passar o mouse no desktop */
  &:hover {
    &::-webkit-scrollbar {
      width: 8px; /* Exibe a barra de rolagem */
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* Estilizando a barra de rolagem para Firefox no desktop */
  scrollbar-width: none; /* Oculta a barra de rolagem inicialmente */

  &:hover {
    scrollbar-width: thin; /* Exibe a barra de rolagem quando hover */
    scrollbar-color: #888 #f1f1f1;
  }

  /* No mobile, mostrar a barra de rolagem sempre */
  @media (max-width: 768px) {
    &::-webkit-scrollbar {
      width: 8px; /* Exibe a barra de rolagem */
    }

    scrollbar-width: thin; /* Exibe a barra de rolagem no Firefox */
  }
`;

export const StyledListItem = styled(ListItem)`
  border-top: 1px solid rgba(0, 0, 0, 0.12); /* Borda para os itens da lista */
  padding: 14px 0px !important;
  justify-content: center !important;
`;

export const AmountBox = styled.div`
  color: #ff7c06;
  text-align: center;
  margin-top: 34px;
`;

export const Amount = styled.div`
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 23px;
    font-weight: 700;
  }
`;

export const AmountRecurrence = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-transform: lowercase;

  @media (max-width: 1199px) {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #ff7c06;
    text-transform: none;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    font-weight: 600;
    border-radius: 30px;
    width: 250px;
    height: 55px;
    color: black;
    margin-top: 20px;

    @media (max-width: 1199px) {
      width: 320px;
      height: 44px;
      margin-top: 17px;
      margin-bottom: 14px;
    }
  }
`;

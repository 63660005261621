import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Stack, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubMenu from "./SubMenu";
import { StyleListMenu } from "./styles";
import LocalStorage from "helpers/LocalStorage";
import { openLink } from "helpers/ManageLinks";
import { iCity } from "data/interfaces/cities";

type PropsNavMenu = {
  direction: "row" | "column";
  justify: string;
  spacing: number;
  click: () => void;
};

export default function NavMenu({ direction, justify, spacing }: PropsNavMenu) {
  const [city, setCity] = useState<iCity>({
    id: 0,
    city: "",
    uf: "",
    state: "",
  });
  const isDesktop = useMediaQuery("(min-width:1200px)");
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const options: any = [
    {
      label: "Ouvidoria",
      link: "/ouvidoria",
      icon: "/images/icons/menu/headphone.png",
    },
    {
      label: "Área do cliente",
      link: "#",
      icon: "/images/icons/menu/person.png",
      rightIcon: true,
      items: [
        { label: "Entrar na área do cliente", link: "/login" },
        { label: "Segunda via fatura", link: "/login" },
        {
          label: "Devolução equipamentos",
          link: "/cliente/devolucao-equipamentos",
        },
        { label: "Desbloqueio cortesia", link: "/login" },
        { label: "Acesso Skeelo", link: "/login" },
        { label: "Acesso Conta Outra Vez", link: "/login" },
        { label: "Portal do assinante", link: "/login" },
      ],
    },
    {
      label: "Trabalhe conosco",
      link: "/trabalhe-conosco",
      icon: "/images/icons/menu/suitcase.png",
    },
    {
      label: `${city.city} - ${city.uf}`,
      link: "/localizacao",
      icon: "/images/icons/menu/pin-location.png",
      rightIcon: true,
    },
  ];

  const handleClick = (event: any) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  const openPage = (page: string) => {
    if (page.includes("http")) {
      openLink(page);
    } else {
      navigate(page);
    }
  };

  useEffect(() => {
    const city = LocalStorage.get("city");
    if (!city) {
      navigate("/localizacao");
    } else {
      setCity(city);
    }
  }, [navigate]);

  return (
    <Stack
      direction={direction}
      justifyContent={justify}
      spacing={{ xs: spacing, sm: spacing, md: spacing, lg: spacing, xl: 6 }}
      alignItems={{
        xs: "initial",
        sm: "initial",
        md: "initial",
        lg: "center",
        xl: "center",
      }}
    >
      {options.map((item: any, index: number) => (
        <Stack
          id={item.label.split(" ").join("") + index}
          key={item.label.split(" ").join("") + index}
          aria-controls={open ? "customer-area" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => {
            if (item.items) {
              handleClick(event);
            } else {
              openPage(item.link);
            }
          }}
          sx={
            !isDesktop
              ? index < options.length - 1
                ? StyleListMenu
                : { ...StyleListMenu, borderBottom: "none" }
              : {}
          }
        >
          <Box
            gap={{
              xs: 0.5,
              md: 1.2,
            }}
            display={{ xs: "flex", md: "inline-flex" }}
            flexWrap={"wrap"}
            alignItems="center"
          >
            <img src={item.icon} alt="icon-facebook" width="21.5px" />

            <p
              style={{
                fontSize: "13px",
                color:
                  location.pathname === item.link || (open && item.items)
                    ? "#FF7C06"
                    : "white",
                fontWeight: 500,
                paddingTop: "2px",
                cursor: "pointer",
              }}
            >
              {item.label}
            </p>

            {item.rightIcon && (
              <KeyboardArrowDownIcon htmlColor="#FFF" fontSize="small" />
            )}
            {item.items && (
              <SubMenu
                items={item.items}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClick}
              />
            )}
          </Box>
        </Stack>
      ))}
      {/* <ButtonStyled
        link={"/monte-seu-plano"}
        customMarginTop={isDesktop ? 0 : 30}
        width={isDesktop ? null : "230px"}
        customColor={isDesktop ? null : "#FF7C06"}
      >
        Monte seu plano
      </ButtonStyled> */}
    </Stack>
  );
}


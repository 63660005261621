import { Box, List, ListItem, TextField } from "@mui/material";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  width: 100%;
  height: 100vh;
  background-image: url("/images/background-localization.png");
  background-size: cover;
  background-position: right 25% bottom 45%;
`;

export const StyledInput = styled(TextField)`
  .MuiInputBase-root {
    color: #fff;
    font-size: 18px;
  }
  .MuiInputLabel-root {
    padding-left: 15px;
    color: #fff;
  }
  .MuiFormLabel-root {
    color: #fff !important;
  }
  .MuiOutlinedInput-notchedOutline {
    background-color: rgba(0, 0, 0, 0.15); /* Cor de fundo da lista */
    border: 1px solid #ff7c06 !important; /* Borda do menu */
    border-radius: 65.8678px !important;
    color: #fff;
    box-shadow: 0px 5px 30px rgba(111, 60, 15, 0.5) !important;
  }
`;

export const StyledListBox = styled(Box)`
  background-color: rgba(0, 0, 0, 0.15); /* Cor de fundo da lista */
  border: 1px solid #ff7c06;
  border-radius: 20px;
  margin-top: 14px !important;
  width: 100%;
  padding: 20px 16px !important;
`;

export const StyledList = styled(List)`
  overflow: auto;
  max-height: 200px;
  padding: 9px 25px !important;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 0.3em; /* Largura da barra de rolagem vertical */
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: #ff7c06;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }
`;

export const StyledListItem = styled(ListItem)`
  border-bottom: 0.647405px solid rgba(255, 255, 255, 0.15); /* Borda para os itens do menu */
  color: #fff;
  font-size: 18px;
  padding: 5px 0px !important;
`;


import styled from "styled-components";

export const Title = styled.div`
  color: #ff7c06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const Text = styled.div`
  color: #ffff;
  font-size: 16px;
  font-weight: 400;

`;

export const OpenCreatePlan = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #ff7c06;
  cursor: pointer;
  text-decoration: underline;
`;
import { Box } from "@mui/material";
import Initial from "../../components/Home/Initial/index";
import Services from "../../components/Home/Services/index";
import OurDifferences from "../../components/Home/OurDifferences/index";
import OurPlans from "components/Home/OurPlans";
import CarouselPlans from "../../components/Home/CarouselPlans/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
  return (
    <Box width="100%" height="100%">
      <Initial />
      <CarouselPlans />
      <OurPlans />
      <Services />
      <OurDifferences />
    </Box>
  );
}


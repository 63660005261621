import { Box, Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Title, TextPrimary, TextSecondary, ResponsiveIcon } from "./styles";

export default function WhyWorkWhitUs() {
  const list: any = [
    {
      title: "Cultura Empresarial",
      description:
        "Valorizamos a diversidade, o trabalho em equipe e a criatividade. Na Itop, você terá a oportunidade de colaborar com profissionais talentosos de diversas áreas, em um ambiente que incentiva o crescimento pessoal e profissional.",
      icon: "/images/workWhitUs/ic-cult.png",
    },
    {
      title: "Desafios Estimulantes",
      description:
        "Estamos na vanguarda da tecnologia e da inovação, enfrentando desafios emocionantes e oportunidades de aprendizado constante. Se você é apaixonado por resolver problemas complexos e está sempre em busca de novos desafios, você encontrará seu lugar aqui.",
      icon: "/images/workWhitUs/ic-des.png",
    },
    {
      title: "Benefícios e Oportunidades",
      description:
        "Oferecemos uma gama abrangente de benefícios competitivos e oportunidades de desenvolvimento profissional, incluindo treinamento contínuo, programas de mentoria e planos de carreira personalizados.",
      icon: "/images/workWhitUs/ic-ben.png",
    },
  ];

  const isMobile = useMediaQuery("(min-width:600px)");
  return (
    <Box bgcolor="#2E2E2E">
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          pt={6}
          pb={6}
          textAlign="center"
          px={1}
        >
          <Title>Por que trabalhar conosco?</Title>
        </Grid>
        <Grid item xs={11} sm={9} md={8} lg={7} xl={6} pt={3}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} sm={12} md={12} lg={9.5} xl={9}>
              {list.map((it: any, index: number) => (
                <List
                  key={index}
                  sx={{
                    width: "100%",
                    bgcolor: "#2E2E2E",
                    padding: "0px 0px",
                  }}
                >
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <ResponsiveIcon alt="icon" src={it.icon} />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ padding: "0px 3%" }}
                      primary={<TextPrimary>{it.title}</TextPrimary>}
                      secondary={
                        <TextSecondary>{it.description}</TextSecondary>
                      }
                    />
                  </ListItem>
                  {index !== list.length - 1 && (
                    <Box
                      py={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 3 }}
                      display="flex"
                      justifyContent="center"
                      px={3}
                    >
                      <Divider color="#C0C0C0" style={{ width: "100%" }} />
                    </Box>
                  )}
                </List>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          pt={3}
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={6}
          mb="-3.5px"
          textAlign="right"
        >
          <img
            width={isMobile ? "" : "100%"}
            alt="man"
            src={
              isMobile
                ? "/images/workWhitUs/man-right-circle.png"
                : "/images/workWhitUs/man-right-circle-center.png"
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

import styled from "styled-components";

export const Title = styled.div`
  text-align: center;
  color: #ff7c06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }
`;

export const TextPrimary = styled.div`
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 25px;

  @media (max-width: 1200px) {
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 15px;
  }
`;

export const TextSecondary = styled.span`
  color: #c0c0c0;
  font-size: 14px;
  font-weight: 200;

  @media (max-width: 1200px) {
    font-size: 12px;
    font-weight: 200;
  }
`;

export const ResponsiveIcon = styled.img`
  width: 100%;
  height: auto;

  @media (min-width: 0px) {
    width: 67px;
    height: 67px;
  }

  @media (min-width: 600px) {
    width: 67px;
    height: 67px;
  }

  @media (min-width: 960px) {
    width: 67px;
    height: 67px;
  }

  @media (min-width: 1200px) {
    width: 95px;
    height: 95px;
  }

  @media (min-width: 1920px) {
    width: 95px;
    height: 95px;
  }
`;

import { MenuItem, useMediaQuery } from "@mui/material";
import { openLink } from "helpers/ManageLinks";
import { StyledMenu } from "./styles";
import { useNavigate } from "react-router";

type iMenuItem = {
  label: string;
  link: string;
};

type PropsNavMenu = {
  items: iMenuItem[]; // Array de itens do menu
  anchorEl: HTMLElement | null; // Elemento ancorado ou nulo
  open: boolean; // Indicador se o menu está aberto
  handleClose: any; // Função para fechar o menu
};

export default function SubMenu({
  items,
  anchorEl,
  open,
  handleClose,
}: PropsNavMenu) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const openPage = (page: string) => {
    if (page.includes("http")) {
      openLink(page);
    } else {
      navigate(page);
    }
  };

  const renderMenuItems = (items: iMenuItem[]) => {
    return items.map((item, index) => (
      <MenuItem
        key={index}
        onClick={() => openPage(item.link)}
        sx={{
          paddingLeft: "5px",
          fontSize: "14px",
        }}
      >
        {item.label}
      </MenuItem>
    ));
  };

  return (
    <>
      {!isDesktop && open ? (
        renderMenuItems(items)
      ) : (
        <StyledMenu
          id="customer-area"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          disableScrollLock={true}
          autoFocus={false}
        >
          {renderMenuItems(items)}
        </StyledMenu>
      )}
    </>
  );
}


import { iPlanCategory } from "./interfaces/plans";

const Plans: iPlanCategory[] = [
  {
    title: "Pessoa física",
    plans: [
      {
        title: "Essencial",
        plans: [
          {
            title: "Essencial Fit",
            speed: 300,
            speedType: "MB",
            amount: 89.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Essencial Plus",
            speed: 600,
            speedType: "MB",
            amount: 99.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Essencial Connect",
            speed: 700,
            speedType: "MB",
            amount: 109.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Essencial Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Smart Top",
        plans: [
          {
            title: "Smart Call",
            speed: 300,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "25 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Link",
            speed: 600,
            speedType: "MB",
            amount: 144.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "25 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Infinity",
            speed: 700,
            speedType: "MB",
            amount: 164.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "35 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Smart Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 229.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
              {
                title: "50 GB para pacote mobile (1)",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Globoplay",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 119.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 129.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 139.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 179.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Globoplay+",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 209.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Globoplay+ | 3 telas",
                icon: "globoplay.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Premiere",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 209.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Premiere",
                icon: "premiere.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Itop Play + Telecine",
        plans: [
          {
            title: "Play Easy",
            speed: 300,
            speedType: "MB",
            amount: 114.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 5",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Now",
            speed: 600,
            speedType: "MB",
            amount: 124.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
            ],
          },
          {
            title: "Play Zone",
            speed: 700,
            speedType: "MB",
            amount: 134.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
            ],
          },
          {
            title: "Exclusive Play",
            speed: 900,
            speedType: "MB",
            amount: 174.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "Telecine",
                icon: "telecine.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
            ],
          },
        ],
      },
      {
        title: "Família Top",
        plans: [
          {
            title: "Conexão Família",
            speed: 600,
            speedType: "MB",
            amount: 204.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "35 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Conexão Plus",
            speed: 700,
            speedType: "MB",
            amount: 264.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "50 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Conexão Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 304.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "ITOP TV",
                icon: "itoptv.png",
              },
              {
                title: "DEEZER",
                icon: "deezer.png",
              },
              {
                title: "HBO MAX",
                icon: "max.png",
              },
              {
                title: "50 GB para pacote mobile (2)",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Pessoa jurídica",
    plans: [
      {
        title: "Enterprise",
        plans: [
          {
            title: "Enterprise Slim",
            speed: 600,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Premium",
            speed: 700,
            speedType: "MB",
            amount: 179.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Enterprise Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 199.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
      {
        title: "Corp",
        plans: [
          {
            title: "Corp Conect",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 25 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Plus",
            speed: 700,
            speedType: "MB",
            amount: 159.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 35 GB",
                icon: "mobile.png",
              },
            ],
          },
          {
            title: "Corp Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 184.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Ligações Ilimitadas + 50 GB",
                icon: "mobile.png",
              },
            ],
          },
        ],
      },
      {
        title: "Business",
        plans: [
          {
            title: "Business Line",
            speed: 600,
            speedType: "MB",
            amount: 149.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Master",
            speed: 700,
            speedType: "MB",
            amount: 169.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
          {
            title: "Business Exclusive",
            speed: 900,
            speedType: "MB",
            amount: 189.9,
            recurrence: "mensal",
            buyLink: "https://linkToBuy.com.br",
            benefits: [
              {
                title: "Wi-Fi 6",
                icon: "wifi.png",
              },
              {
                title: "Skeelo Ebooks",
                icon: "skeelo.png",
              },
              {
                title: "Conta Outra Vez!",
                icon: "contaoutravez.png",
              },
              {
                title: "UBOOK",
                icon: "ubook.png",
              },
              {
                title: "Fixo Ilimitado",
                icon: "fixo.png",
              },
            ],
          },
        ],
      },
    ],
  },
];

export default Plans;

import { iCarousel } from "./interfaces/carousel-plans";

const CarouselPlans: iCarousel[] = [
  {
    link: "",
    title: "Promoção - Essencial Plus",
    imgDesktop: "images/plans/essentialPlus_desktop.jpg",
    imgMobile: "images/plans/essentialPlus_mobile.jpg",
    video: "",
    cities: [], // caso esteja vazio indica que será mostrado para todas as cidades
  },
  {
    link: "",
    title: "Promoção - 500MB",
    imgDesktop: "images/plans/500mb_desktop.jpg",
    imgMobile: "images/plans/500mb_mobile.jpg",
    video: "",
    cities: [], // caso esteja vazio indica que será mostrado para todas as cidades
  },
  // {
  //   link: "",
  //   title: "Promoção - Plano 600 MB em até 60 dias",
  //   imgDesktop: "images/plans/plan600mb60dias_desktop.jpeg",
  //   imgMobile: "images/plans/plan600mb60dias_mobile.jpeg",
  //   video: "",
  //   cities: [
  //     { city: "Machado", uf: "MG" },
  //     { city: "Paraguaçu", uf: "MG" },
  //     { city: "Areado", uf: "MG" },
  //     { city: "Ribeirão Preto", uf: "SP" },
  //     { city: "Belo Horizonte", uf: "MG" },
  //     { city: "Sete Lagoas", uf: "MG" },
  //     { city: "Inhaúma", uf: "MG" },
  //   ], // caso esteja vazio indica que será mostrado para todas as cidades
  // },
  // {
  //   link: "",
  //   title: "Promoção - Plano 500 MB por R$ 89,90",
  //   imgDesktop: "images/plans/plan500mb_desktop.jpeg",
  //   imgMobile: "images/plans/plan500mb_mobile.jpeg",
  //   video: "",
  //   cities: [
  //     { city: "Paraguaçu", uf: "MG" },
  //     { city: "Areado", uf: "MG" },
  //     { city: "Ribeirão Preto", uf: "SP" },
  //     { city: "Belo Horizonte", uf: "MG" },
  //     { city: "Sete Lagoas", uf: "MG" },
  //     { city: "Inhaúma", uf: "MG" },
  //   ], // caso esteja vazio indica que será mostrado para todas as cidades
  // },
  // {
  //   link: "",
  //   title: "Promoção - Oqtem + Itop",
  //   imgDesktop: "images/plans/planItopOqtem_desktop.jpeg",
  //   imgMobile: "images/plans/planItopOqtem_mobile.jpeg",
  //   video: "",
  //   cities: [
  //     { city: "Alfenas", uf: "MG" },
  //   ], // caso esteja vazio indica que será mostrado para todas as cidades
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Areado",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/LHxL00V9E_Q",
  //   cities: [{ city: "Areado", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Machado",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/NVORdPrsKYc",
  //   cities: [{ city: "Machado", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Belo Horizonte",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/XQJA40AdvhY",
  //   cities: [{ city: "Belo Horizonte", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Inhaúma",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/HVjfND7hQWs",
  //   cities: [{ city: "Inhaúma", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Paraguaçu",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/r6VrCCF_UEc",
  //   cities: [{ city: "Paraguaçu", uf: "MG" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Ribeirão Preto",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/_JuMk4L1iRg",
  //   cities: [{ city: "Ribeirão Preto", uf: "SP" }],
  // },
  // {
  //   link: "",
  //   title: "Promoção Vídeo - Sete Lagoas",
  //   imgDesktop: "",
  //   imgMobile: "",
  //   video: "https://youtu.be/MXecQw79_68",
  //   cities: [{ city: "Sete Lagoas", uf: "MG" }],
  // },
];
export default CarouselPlans;

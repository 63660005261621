const openLink = (link: string, target: string = "_self") => {
  window.open(link, target);
};

const createLinkWhatsapp = (number: string, message: string): string => {
  const cleanNumber = (number.match(/\d/g) || []).join("");
  const whatappLink = `https://wa.me/55${cleanNumber}?text=${message}`;

  return whatappLink;
};

export { openLink, createLinkWhatsapp };


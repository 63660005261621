import styled from "styled-components";

export const Title = styled.div`
  color: #000000;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1199px) {
    font-size: 29px;
    font-weight: 700;
  }
`;

export const SubTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 400;

  @media (max-width: 1199px) {
    font-size: 15px;
    font-weight: 400;
    text-align: start;
  }
`;

import "react-toastify/dist/ReactToastify.css";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import {
  Title,
  StyledButton,
  StyledTextField,
  PageChip,
  SubTitle,
  ColoredTitle,
  Description,
  BoldColoredTitle,
} from "./styles";
import { styled } from "@mui/system";
import Config from "data/config";
import { mail } from "services";
import SmallStyled from "components/SmallStyled";
import { ToastContainer, toast } from "react-toastify";
import { createLinkWhatsapp, openLink } from "helpers/ManageLinks";

interface iFormContact {
  name: string;
  protocol: string;
  subject: string;
  request_details: string;
}

export default function InternalAffairsForm() {
  const StyledSelect = styled(Select)({
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "rgb(232, 241, 250)",
    "& .MuiSelect-outlined": {
      backgroundColor: "rgb(232, 241, 250)",
      borderRadius: "8px",
      borderWidth: "2px",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: "#ff7c06",
      borderRadius: "8px",
    },

    "& .MuiInputBase-input": {
      fontFamily: "'Mulish', sans-serif",
      fontWeight: "400",
      fontSize: "16px",
      borderRadius: "8px",
    },
  });

  const options: any = Config.pages.internalAffairs.form.subjects;

  const {
    register,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<iFormContact>();

  const onSubmit: SubmitHandler<iFormContact> = async (data) => {
    try {
      const resonse = await mail.sendMail({
        type: "contact",
        data,
      });
      if (resonse.status) {
        setValue("name", "");
        setValue("protocol", "");
        setValue("subject", "");
        setValue("request_details", "");
        toast("Solicitação de contato realizada com sucesso!", {
          type: "success",
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error: any) {
      toast("Erro ao solicitar contato. Redirecionando para o Whatsapp.", {
        type: "error",
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        const message = `Olá acessei o website e tenho algumas duvidas, esses são meus dados:
        \n
        \nNome *${data.name}*
        \nProtocolo: *${data.protocol || ""}*
        \nAssunto: *${data.subject || ""}*
        \nDescrição: *${data.request_details || ""}*`;

        const link = createLinkWhatsapp(
          Config.contact.whatsapp.number,
          encodeURIComponent(message)
        );

        openLink(link, "_blank");
      }, 3000);
    }
  };
  return (
    <Box py={7} bgcolor="#2E2E2E">
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          px={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          pb={4}
        >
          <PageChip>FALE CONOSCO</PageChip>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>Entre em contato</Title>
        </Grid>
        <Grid item xs={9} sm={9} md={10} lg={12} xl={12} py={5}>
          <SubTitle>
            Você pode entrar em contato com a
            <ColoredTitle> Ouvidoria da Itop Telecom</ColoredTitle> via:
          </SubTitle>
        </Grid>
        <Grid item xs={10.5} sm={9} md={7} lg={5} xl={4}>
          <Description>
            <img
              style={{ marginRight: "10px", marginBottom: "-4px" }}
              src="images/internalAffairs/check-icon.png"
              alt="icon"
              width={22}
              height={22}
            />
            <BoldColoredTitle>Formulário Online:</BoldColoredTitle> Preencha
            nosso formulário online abaixo para enviar sua mensagem diretamente
            para nossa equipe da Ouvidoria.
          </Description>
          <Description>
            <img
              style={{ marginRight: "10px", marginBottom: "-4px" }}
              src="images/internalAffairs/mail-icon.png"
              alt="icon"
              width={22}
              height={22}
            />
            <BoldColoredTitle>E-mail:</BoldColoredTitle> Envie um e-mail para
            auditoria@itop.net.br com suas questões e detalhes do seu caso.
          </Description>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid pt={3} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="Nome completo do assinante"
              {...register("name", { required: true, minLength: 5 })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === "required" && (
              <SmallStyled>Nome é obrigatório</SmallStyled>
            )}
            {errors.name?.type === "minLength" && (
              <SmallStyled>
                Nome deve possuir no mínimo 5 caracteres
              </SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              placeholder="Protocolo (Caso tenha)"
              {...register("protocol", { minLength: 5 })}
            />
            {errors.protocol?.type === "minLength" && (
              <SmallStyled>
                Protocolo deve possuir no mínimo 5 caracteres
              </SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              name="subject"
              render={({ field: { onChange, value } }) => (
                <StyledSelect
                  displayEmpty
                  variant="outlined"
                  defaultValue=""
                  renderValue={(selected: any) => {
                    if (selected.length === 0) {
                      return (
                        <span style={{ color: "#2E2E2E", opacity: "60%" }}>
                          Selecione o assunto
                        </span>
                      );
                    }
                    return selected;
                  }}
                  inputProps={{
                    MenuProps: { disableScrollLock: true },
                  }}
                  value={value || ""}
                  onChange={onChange}
                >
                  {options.map((it: any, index: number) => (
                    <MenuItem key={index} value={it}>
                      {it}
                    </MenuItem>
                  ))}
                </StyledSelect>
              )}
            />

            {errors.subject?.type === "required" && (
              <SmallStyled>Assunto é obrigatório</SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledTextField
              multiline
              rows={4}
              placeholder="Descreva a sua solicitação"
              {...register("request_details", { required: true, minLength: 5 })}
            />
            {errors.request_details?.type === "required" && (
              <SmallStyled>Descrição é obrigatório</SmallStyled>
            )}
            {errors.request_details?.type === "minLength" && (
              <SmallStyled>
                Descrição deve possuir no mínimo 5 caractéres
              </SmallStyled>
            )}
          </Grid>
        </Grid>

        <Grid pt={1.5} container justifyContent="center" alignItems="center">
          <Grid xs={11} sm={9} md={6} lg={4.5} xl={3.5} item px={1}>
            <StyledButton type="submit">Enviar mensagem</StyledButton>
          </Grid>
        </Grid>
      </form>
      <ToastContainer />
    </Box>
  );
}


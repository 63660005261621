import styled from "styled-components";
import { TextField, Select, Button } from "@mui/material";

export const Title = styled.div`
  text-align: center;
  color: #ff7c06;
  font-size: 40px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
  }
`;

export const StyledButton = styled(Button)`
  && {
    background-color: #ff7c06;
    text-transform: none;
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    font-weight: 800;
    border-radius: 30px;
    width: 100%;
    color: black;
    height: 55px;

    &:hover {
      background-color: #F9963D;
      color: black;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiOutlinedInput-root {
    background-color: rgb(232, 241, 250);
    border-radius: 8px;
    &.Mui-focused fieldset {
      border-color: #ff7c06;
    }
  }
  & .MuiInputBase-input {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
  }
  & .MuiInputBase-input::placeholder {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #2e2e2e;
    opacity: 60%;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 8px;
  background-color: rgb(232, 241, 250);
  & .MuiSelect-outlined {
    background-color: rgb(232, 241, 250);
    border-radius: 8px;
    border-width: 2px;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: #ff7c06;
    border-radius: 8px;
  }
  & .MuiInputBase-input {
    font-family: 'Mulish', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
  }
`;
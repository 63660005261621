import { iConfig } from "./interfaces/config";

const Config: iConfig = {
  api_email: "https://itop.net.br",
  pages: {
    internalAffairs: {
      form: {
        subjects: ["Assunto 1", "Assunto 2"],
      },
    },
    location: {
      itopCities: [
        { city: "Alfenas", uf: "MG" },
        { city: "Areado", uf: "MG" },
        { city: "Belo Horizonte", uf: "MG" },
        { city: "Inhaúma", uf: "MG" },
        { city: "Machado", uf: "MG" },
        { city: "Paraguaçu", uf: "MG" },
        { city: "Paraopeba", uf: "MG" },
        { city: "Ribeirão Preto", uf: "SP" },
        { city: "Santa Luzia", uf: "MG" },
        { city: "Sete Lagoas", uf: "MG" },
      ],
    },
    returnEquipment: {
      form: {
        hours: ["07:00", "08:00", "09:00"],
      },
    },
  },
  carousel: {
    // caso não tenha link setado para os planos é enviado mensagem
    whatsapp: {
      number: "0800 591 8017",
      message: "Olá! queria saber mais sobre o plano: [TITLE].",
    },
  },
  contact: {
    whatsapp: {
      number: "0800 591 8017",
      message:
        "Olá, gostaria de saber mais sobre os serviços/produtos da iTop Telecom. ",
    },
    email: "auditoria@itop.net.br",
  },
  mount_plan: {
    marksBroadband: [
      {
        value: 0,
        label: "200 MB",
        price: 40,
        wifiType: "Wi-Fi 5",
      },
      {
        value: 33,
        label: "500 MB",
        price: 60,
        wifiType: "Wi-Fi 6",
      },
      {
        value: 66,
        label: "600 MB",
        price: 80,
        wifiType: "Wi-Fi 6",
      },
      {
        value: 100,
        label: "800 MB",
        price: 100,
        wifiType: "Wi-Fi 6",
      },
    ],

    // opções de pacote mobile
    marksMobilePackage: [
      {
        value: 0,
        label: "Nenhum",
        price: 0,
      },
      {
        value: 33,
        label: "20 GB",
        price: 35,
      },
      {
        value: 66,
        label: "30 GB",
        price: 45,
      },
      {
        value: 100,
        label: "50 GB",
        price: 55,
      },
    ],

    // opções de pacote fixo
    marksFixedPackage: [
      {
        value: 0,
        label: "500 minutos",
        price: 30,
      },
      {
        value: 33,
        label: "800 minutos",
        price: 55,
      },
      {
        value: 66,
        label: "1.000 minutos",
        price: 65,
      },
      {
        value: 100,
        label: "Ilimitado",
        price: 80,
      },
    ],

    // opções de streams
    streamingOptions: [
      {
        value: false,
        label: "ITOP TV (Grátis)",
        price: 0,
        icon: "itoptv.png",
      },
      {
        value: false,
        label: "Deezer",
        price: 25,
        icon: "deezer.png",
      },
      {
        value: false,
        label: "Direct TV GO",
        price: 30,
        icon: "itoptv.png",
      },
      {
        value: false,
        label: "HBO MAX",
        price: 59.99,
        icon: "max.png",
      },
      {
        value: false,
        label: "UBOOK",
        price: 40,
        icon: "ubook.png",
      },
    ],
  },
};

export default Config;

